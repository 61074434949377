// @ts-ignore
import {LOCAL_STORAGE_KEYS_NAMES} from "@/constants/local_storage_keys_names";

class StorageService {

    getItem(key: LOCAL_STORAGE_KEYS_NAMES): any | null {
        return localStorage.getItem(key);
    }

    setItem(key: LOCAL_STORAGE_KEYS_NAMES, value: any): void {
        localStorage.setItem(key, value);
    }


}

export default new StorageService();
