import ICookieOptions from "@/interfaces/ICookieOptions";
import type {OrderStatusJobType} from "../types/OrderStatusJobType";
import timezones from 'timezones-list';

type TimeObj = { hours: number; minutes: number; seconds?: number };


export const getCookie = (name: string): string | undefined => {
    const matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const setCookie = (name: string, value: string, options: ICookieOptions = {}): void => {

    options = {
        path: '/',
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        // @ts-ignore
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
};

export const deleteCookie = (name: string): void => {
    setCookie(name, "", {
        'max-age': -1
    })
}

export const formatNumber = (num: string | number): string => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
}

export const generateId = (): string => {
    return (Math.random() + 1).toString(36).substring(7);
};

export const generateNumber = (n: number): string => {
    let number = "";
    for (let i = 0; i < n; i++) {
        number += Math.floor(Math.random() * 10);
    }
    return number;
};

export const decodeStatusJob = (value: OrderStatusJobType) => {
    const dic = {
        "wait": "Ожидает запуска",
        "work": "В работе",
        "finish": "Закончен",
        "error": "Ошибка",
    };
    return dic[value] || "Статус не найден";
};

export const parseDateFromIso = (value: string) => {
    const arr: string[] = value.split("T");
    const date = arr[0];
    const time = arr[1].split('.')[0];
    return `${date} ${time}`;
};

export const parseWorkTime = (times: TimeObj[]): string => {
    const getTimeFormat = (time: number): string => {
        return `${time < 10 ? time + '0' : time}`;
    };
    return `${getTimeFormat(times[0].hours)}:${getTimeFormat(times[0].minutes)} - ${getTimeFormat(times[1].hours)}:${getTimeFormat(times[1].minutes)}`;
};

export const decodeWorkTime = (workTime: string): TimeObj[] => {
    if (!workTime) {
        return [{hours: 10, minutes: 0}, {hours: 20, minutes: 0}];
    }
    const arr = workTime.split(' - ');
    const timeFrom = arr[0].split(':');
    const timeTo = arr[1].split(':');
    return [
        {hours: parseInt(timeFrom[0]), minutes: parseInt(timeFrom[1])},
        {hours: parseInt(timeTo[0]), minutes: parseInt(timeTo[1])},
    ]
};

export const getTimezoneNameByValue = (value: string) => {
    for (const timezone of timezones) {
        if (timezone.tzCode === value) {
            return timezone.name;
        }
    }
    return "";
};