import api from "./api";
import TokenService from "./token.service";
import axios from "axios/index";
import {API_URL} from "@/constatns";
import {setCookie} from "@/services/utils.service";

export const login = async (email: string, password: string) => {
    try {
        const {data} = await api
            .post("/login", {
                email: email.trim().toLocaleLowerCase(),
                password: password.trim()
            })
        const {
            access_token: accessToken,
            expires_in: expiresIn,
            refresh_token: refreshToken,
            refresh_token_expires_in: refreshTokenExpiresIn
        } = data;

        TokenService.updateLocalAccessToken(accessToken, expiresIn);
        TokenService.updateLocalRefreshToken(refreshToken, refreshTokenExpiresIn);

        return true;
    } catch (e) {
        console.error(`Error handled: `, e);
        return false;
    }

}

export const register = async (name: string, email: string, password: string) => {

    try {
        const {data} = await api
            .post("/register", {
            name: name,
            email: email.trim().toLocaleLowerCase(),
            password: password.trim()
        });

        const {
            access_token: accessToken,
            expires_in: expiresIn,
            refresh_token: refreshToken,
            refresh_token_expires_in: refreshTokenExpiresIn
        } = data;

        TokenService.updateLocalAccessToken(accessToken, expiresIn);
        TokenService.updateLocalRefreshToken(refreshToken, refreshTokenExpiresIn);

        return true;

    } catch (error) {
        console.error(`Error handled: `, error);
        return false;
    }
};

export const checkAuth = () => {
    try {
        return !!TokenService.getLocalAccessToken();
    } catch (e) {
        return false;
    }
};

export const logout = () => {
    TokenService.removeTokens();
}