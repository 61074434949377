import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import "./index.css"
import {createI18n} from 'vue-i18n'
import locales from "./locales";
import mitt from 'mitt';
const emitter = mitt();

import setupInterceptors from './services/setupInterceptors';
setupInterceptors();

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const i18n = createI18n({
    legacy: false,
    locale: 'ru',
    fallbackLocale: 'en',
    messages: locales,
});

const app = createApp(App);

app.config.globalProperties.emitter = emitter;

app.component('VueDatePicker', VueDatePicker);
app.use(router);
app.use(i18n);
app.mount('#app');
