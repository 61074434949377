import api from './api';

class ShopService {

    read(data: any = {}) {
        return api.get("shop/read", {params : data});
    }

    create(data: any = {}) {
        return api.post("shop/create", data);
    }
    update(data: any = {}) {
        return api.post("shop/update", data);
    }

    delete(data: any = {}) {
        return api.post("shop/delete", data);
    }

}

export default new ShopService();