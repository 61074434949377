export default {
    ru: {
        common: {
            pageComingSoon: "Страница скоро будет готова"
        },
        components: {
            ModalDialog: {
                btnApply: "Подтвердить",
                btnCancel: "Отмена",
                confirmDeleteCommon: "Вы действительно хотите удалить?",
                titleDeleteCommon: "Удаление",
                titleDeleteErrorCommon: "Ошибка",
                textDeleteErrorCommon: "При удалении возникла ошибка на сервере.",
            },
            Nav: {
                exit: "Выход"
            },
            datepicker: {
                select: "Выбрать",
                cancel: "Отменить",
                today: "Сегодня",
                thisMonth: "Текущий месяц",
                thisWeek: "Текущая неделя",
                lastWeek: "Последняя неделя",
                lastMonth: "Последний месяц",
                thisYear: "Этот год",
                thisYearSlot: "Этот год слот",
            }
        },
        orders: {
            title: "Заказы"
        },
        newsletter: {
            title: "Рассылка",
            selectDate: "Выберите дату",
            selectProject: "Выберите проект",
            message: "Сообщение",
            fieldName: "Название",
            btnSubmit: "Создать"
        },
        dashboard: {
            title: "Главная",
        },
        qrcode: {
            title: "Сканер QR"
        },
        statisticsSources: {
            title: "Источники",
            btnAdd: "Добавить",
            source: "Источник",
            table: {
                actions: "Действия",
                btnDelete: "Удалить"
            },
            count: "Количество"
        },
        statistics: {
            title: "Статистика",
            selectedData: "Selected date is",
            table: {
                source: "Источник",
                countVisit: "Переходы",
                countSales: "Продажи",
                conversion: "Конверсия",
                sumSales: "Сумма продаж",
                revenue: "Прибыль",
            }
        },
        support: {
            title: "Служба поддержки"
        },
        login: {
            title: "Вход",
            email: "Email",
            password: "Пароль",
            enter: "Войти",
            errorAuth: "Ошибка авторизации",
        }
    },
    en: {
        dashboard: {
            title: "Рабочий стол",

        },
        statistics: {
            title: "Статистика"
        },

        support: {
            title: "Служба поддержки"
        },
        login: {
            title: "Вход",
            email: "Email",
            enterEmail: "Введите Email",
            password: "Пароль",
            enterPassword: "Введите Пароль",
            enter: "Войти",
            errorAuth: "Ошибка авторизации",

        }
    }
}