import {getCookie, setCookie, deleteCookie} from "@/services/utils.service";

class TokenService {
    getLocalRefreshToken(): string | undefined {
        return getCookie("refresh_token");
    }

    getLocalAccessToken(): string | undefined {
        return getCookie("access_token");
    }

    updateLocalAccessToken(token: string, expiresIn: number): void {
        const currentTime = new Date().getTime();
        const expires = new Date(currentTime + expiresIn);
        setCookie('access_token', token, {expires: expires});
    }

    updateLocalRefreshToken(token: string, expiresIn: number) {
        const currentTime = new Date().getTime();
        const expires = new Date(currentTime + expiresIn);
        setCookie('refresh_token', token, {expires: expires});
    }

    removeTokens() {
        deleteCookie('access_token');
        deleteCookie('refresh_token');
    }
}

export default new TokenService();